// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bX_k4";
export var caseStudyBackground = "bX_nk";
export var caseStudyBackground__lineColor = "bX_kW";
export var caseStudyCta__bgLight = "bX_nl";
export var caseStudyHead = "bX_k6";
export var caseStudyHead__imageWrapper = "bX_kT";
export var caseStudyProjectsSection = "bX_k5";
export var caseStudyQuote__bgRing = "bX_k1";
export var caseStudySolution__ring = "bX_kZ";
export var caseStudySolution__ringBottom = "bX_nm";
export var caseStudySolution__ringRight = "bX_nn";
export var caseStudyTechnology__bg = "bX_np";
export var caseStudy__bgDark = "bX_kS";
export var caseStudy__bgLight = "bX_kR";